import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import BailleulLogo from '../assets/svg/Bailleul.inline.svg'
import ImazolLogo from '../assets/svg/imazol.inline.svg'
import ImazolPlusLogo from '../assets/svg/imazol-plus.inline.svg'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Scrollspy from 'react-scrollspy'

import ThemeContext from "../context/MenuContext"


const Header = ({ siteTitle, location }) => {
    
    const [scrolled, setScrolled] = useState(false);
    
    const isMain = location === '/'
            
    useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 235;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        document.removeEventListener('scroll', handleScroll);
    };
    }, [scrolled]);
    
    return (
        
  <ThemeContext.Consumer>
    {theme => (
                
        <header data-active={ isMain ? scrolled : 'fixed' }>
            <div className="navbar container grid-xl">
                <div className="thing"></div>
                <section className="navbar-section left">
                    <a href="/#intro" className="imazol-plus-brand"><ImazolPlusLogo /></a>
                    <a href="/#intro" className="imazol-brand"><ImazolLogo /></a>
                </section>
                <section className="navbar-section right">
                    <a href="http://bailleul.com" target="_blank" rel="noreferrer" className="auxilia-brand hide-md"><BailleulLogo /></a>
                    <button className="off-canvas-toggle btn btn-primary btn-action s-circle show-md" onClick={theme.toggleDark}>
                        <i className="icon icon-menu"></i>
                    </button>
                    
                </section>
                <nav className="navbar-section-menu hide-md">
                    <div className="nav-holder">
                        
                    <Scrollspy
                        className='btn-group btn-group-block'
                        items={ ['intro', 'imazolplus', 'imazolnahapasta','misonseen', 'symptomid', 'ravi','info'] }
                        currentClassName="active"
                        componentTag="div"
                        offset={ -235 }
                    >
                        <AnchorLink className="d-none" to="#intro" title="Intro" />
                        <AnchorLink className="btn btn-menu text-bold imazol-btn" to="#imazolplus" title="Imazol Plus" />
                        <AnchorLink className="btn btn-menu text-bold pasta-btn" to="#imazolnahapasta" title="Imazol Nahapasta" />
                        <AnchorLink className="btn btn-menu text-uppercase text-bold" to="#misonseen" title="Mis on seen?" />
                        <AnchorLink className="btn btn-menu text-uppercase text-bold" to="#symptomid" title="Sümptomid" />
                        <AnchorLink className="btn btn-menu text-uppercase text-bold" to="#ravi" title="Ravi" />
                        <AnchorLink className="btn btn-menu text-uppercase text-bold" to="#info" title="Info" />
                    </Scrollspy>
                    </div>

                </nav>
            </div>
        </header>
        )}
  </ThemeContext.Consumer>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
