import React from "react"
import ImazolLogo from '../assets/svg/imazol.inline.svg'
import ImazolPlusLogo from '../assets/svg/imazol-plus.inline.svg'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Scrollspy2 from 'react-scrollspy'

import ThemeContext from "../context/MenuContext"

const MobNav = () => {
    
    
    return (
        
  <ThemeContext.Consumer>
    {theme => (
        
        <>
            <div className="navbar container">
                <section className="navbar-section left">
                    <a href="/#intro" className="imazol-plus-brand"><ImazolPlusLogo /></a>
                    <a href="/#intro" className="imazol-brand"><ImazolLogo /></a>
                </section>
                <section className="navbar-section right">
                    <button className="off-canvas-toggle btn btn-primary btn-action s-circle show-md" onClick={theme.toggleDark}>
                        <i className="icon icon-cross"></i>
                    </button>
                </section>
            </div>
                <Scrollspy2
                    className='mob-nav'
                    items={ ['intro', 'imazolplus', 'imazolnahapasta','misonseen', 'symptomid', 'ravi','info'] }
                    currentClassName="active"
                    componentTag="div"

                >
                    <AnchorLink className="d-none" to="#intro" title="Intro" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-bold imazol-btn" to="#imazolplus" title="Imazol Plus" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-bold pasta-btn" to="#imazolnahapasta" title="Imazol Nahapasta" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-uppercase text-bold" to="#misonseen" title="Mis on seen?" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-uppercase text-bold" to="#symptomid" title="Sümptomid" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-uppercase text-bold" to="#ravi" title="Ravi" />
                    <AnchorLink onAnchorLinkClick={theme.toggleDark} className="btn btn-link text-uppercase text-bold" to="#info" title="Info" />
                </Scrollspy2>

        </>
        )}
  </ThemeContext.Consumer>
    )
}



export default MobNav
