import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import MobNav from "./mobnav"
import Footer from "./footer"
import "../assets/scss/spectre.scss"

import { ParallaxProvider } from 'react-scroll-parallax';

import { Parallax } from 'react-scroll-parallax';
import Bubbles from '../assets/svg/bubbles.inline.svg'
import Bubbles2 from '../assets/svg/bubbles2.inline.svg'
import Bubbles3 from '../assets/svg/bubbles3.inline.svg'
import Cloudy from '../assets/svg/cloudy.inline.svg'

import ThemeContext from '../context/MenuContext'


const Layout = ({ children, location }) => {
  

  return (
      
<ThemeContext.Consumer>
{theme => (
              
    <ParallaxProvider>
        <Parallax className="bubbles" y={[-80, 80]}>
            <Bubbles />
        </Parallax>
        <Parallax className="bubbles2 hide-sm" y={[200, 120]}>
            <Bubbles2 />
        </Parallax>
        <Parallax className="cloudy hide-sm" y={[-50, 0]}>
            <Cloudy />
        </Parallax>
        
        <div className="off-canvas">
            <div data-active={theme.dark ? 'true' : 'false'} className="off-canvas-sidebar">
            <MobNav />
            </div>
            <a className="off-canvas-overlay" href="#close"></a>
            <div className="off-canvas-content">
                <Header location={location}  />
                <main className="container grid-xl">{children}</main>
                <Footer />

            </div>
                <div className="alert">
                <p>Käsimüügiravim. Tähelepanu! Tegemist on ravimiga. Enne tarvitamist lugege tähelepanelikult pakendis olevat infolehte.<br className="show-xl" /> Kaebuste püsimise korral või ravimi kõrvaltoimete tekkimisel pidage nõu arsti või apteekriga.<br/> Kõrvaltoimed Pakendi infoleht - <a className="text-dark" style={{color: '#fff', fontWeight: 'bolder', textDecoration: 'underline'}} href="https://www.ravimiregister.ee/Data/PIL/PIL_1314795.pdf" target="_blank" rel="noreferrer">www.ravimiregister.ee</a> </p>
                </div>
            <Bubbles3 style={{ position: 'absolute', bottom: '200px', zIndex: '-2', width: '100%' }} className="bubbles3" />
        </div>
        
    </ParallaxProvider>
    
       )}
      </ThemeContext.Consumer>
  )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,

}

export default Layout
