import PropTypes from "prop-types"
import React from "react"
import AuxiliaLogo from '../assets/svg/Auxilia.inline.svg'
import BailleulLogo from '../assets/svg/Bailleul.inline.svg'


const Header = ({ siteTitle }) => (
    <footer id="info">
        <div className="container grid-xl" style={{marginBottom: '4rem', marginTop: '3rem'}} >
            <div className="columns text-center">
                <div className="column col-6 col-md-12" style={{marginBottom: '3rem'}}>
                    <p><b>Müügiloa hoidja:</b><br/>Laboratoires Bailleul S.A. 10-12,<br /> Avenue Pasteur 2310 g. Luxemburg</p>
                    <a href="http://bailleul.com" rel="noreferrer" target="_blank"><BailleulLogo style={{height: '50px'}} /></a>
                    <a href="http://bailleul.com" rel="noreferrer" target="_blank">www.bailleul.com</a>
                </div>
               <div className="column col-6 col-md-12" style={{marginBottom: '3rem'}}>
                    <p><b>Müügiloa hoidja esindaja Eestis:</b><br/> Auxilia Pharma, Lelle 24, 11318 Tallinn,<br /> info@auxiliapharma.eu</p>
                   <a href="https://auxiliapharma.eu" rel="noreferrer" target="_blank"><AuxiliaLogo style={{height: '50px'}} /></a>
                   <a href="https://auxiliapharma.eu" rel="noreferrer" target="_blank">www.auxiliapharma.eu</a>
                </div>
            </div>

        </div>
    </footer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
